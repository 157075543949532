import "./Button.css";
const Button = (props: {
  id: string;
  href: string;
  name: string | (string | JSX.Element)[];
}) => {
  return (
    <a
      className="button"
      id={props.id}
      rel="noreferrer"
      target="_blank"
      href={props.href}
    >
      {props.name}
    </a>
  );
};
export default Button;
