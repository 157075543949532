import { useState } from "react";
import axios, { AxiosError } from "axios";
import { useQuery } from "react-query";
import { ClipLoader } from "react-spinners";
import { useSearchParams } from "react-router-dom";

import OopsPage from "#components/OopsPage";
import { apiURL } from "#components/ApiUrlConverter";
import DisplayCard from "#services/one-card/DisplayCard";
import DiningHoursIcon from "#images/icons/icon_dining_hours.png";

import "./OneCardPage.css";

// Set API path for environment
const endpoint = apiURL("/api/v1/users");

const OneCardPage = () => {
  // URL parameters to set data for page
  // Get url params
  const [page, ,] = useSearchParams();
  const devMode = Boolean(page.get("dev") ?? false);
  const mealBal = Number(page.get("meal"));
  const guestBal = Number(page.get("guest"));
  const cougBuck = Number(page.get("bucks"));

  const endpointApi =
    devMode === true ? endpoint.replace(/v1/g, "public") : endpoint;

  const [hasAccount, setHasAccount] = useState(false);
  const [hasDiningPlan, setHasDiningPlan] = useState(false);
  // It is safer to default to student being true, since this just results
  // in providing extra information to the user, which may not be relevant
  const [isStudent, setIsStudent] = useState(true);

  // Set up call for user profile data
  const fetchUserProfile = async () => {
    const res = await axios.get(`${endpointApi}/profile`);
    return res;
  };

  // Set up call for user onecard/dining data
  const fetchOneCardData = async () => {
    const res = await axios.get(`${endpointApi}/onecard`);
    return res;
  };

  // Look up if user is student
  const { isSuccess: userIsSuccess } = useQuery(
    "userProfile",
    fetchUserProfile,
    {
      onSuccess: (userData) => {
        // Since staff and faculty tend to keep student personas
        // if they were previously students, we need to check if a
        // user is currently an employee as well as checking for
        // the student personas
        setIsStudent(
          !Object.hasOwn(userData.data, "jobtitle") &&
            (userData.data.PERSONAS.includes("STU") ||
              userData.data.PERSONAS.includes("UG") ||
              userData.data.PERSONAS.includes("GR")),
        );
      },
      staleTime: Infinity,
    },
  );

  // Look up cougar bucks and dining plan
  const {
    isSuccess,
    isError,
    data: patronData,
  } = useQuery("onecard", fetchOneCardData, {
    onError: (error: AxiosError) => {
      if (error?.response?.status === 401) {
        window.location.reload();
      }
    },
    onSuccess: (patronData) => {
      // At a minimum, everyone will have a cougar bucks account
      if (patronData.data.cougarBucksBalance) {
        setHasAccount(true);
        // The most minimal dining plan has flex dollars
        if (patronData.data.flexDollarsBalance) {
          setHasDiningPlan(true);
        }
      }
    },
    staleTime: Infinity,
  });

  // Set values if in dev mode
  if (devMode) {
    if (patronData) {
      patronData.data.cougarBucksBalance ??= cougBuck;
      patronData.data.mealsBalance ??= mealBal;
      patronData.data.guestMealsBalance ??= guestBal;
    }
  }

  // Toggle Modal dining Houres
  const showHours = (status: boolean) => {
    const hoursModal = document.getElementById("hours-modal");
    const modalBackground = document.getElementById("modal-background");
    const modalStatus = status === true ? "block" : "none";
    if (hoursModal) hoursModal.style.display = modalStatus;
    if (modalBackground) modalBackground.style.display = modalStatus;
  };

  if (isError) {
    return <OopsPage />;
  } else if (isSuccess && userIsSuccess) {
    return (
      <section id="onecard-page">
        <DisplayCard
          hasAccount={hasAccount}
          isStudent={isStudent}
          hasDiningPlan={hasDiningPlan}
          patronData={patronData}
        />
        <button
          id="hours-button"
          onClick={() => showHours(true)}
          onKeyDown={() => showHours(true)}
        >
          <img id="hours-image" src={DiningHoursIcon} alt="Dining Hours Icon" />
        </button>
        <div id="modal-background" className="modal">
          <div id="hours-modal" className="modal-content">
            <button
              className="close-button"
              onClick={() => showHours(false)}
              onKeyDown={() => showHours(false)}
            >
              <div>&#xD7;</div>
            </button>
            <iframe
              src="https://apu.cafebonappetit.com/#cafe-hours"
              title="Cafe Hours and Menu"
              id="hours-iframe"
            />
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <div className="cliploader-spinner">
        <ClipLoader color={"#700"} size={100} />
      </div>
    );
  }
};

export default OneCardPage;
