// APU Logos
import LogoHzNormPNG from "#images/APUlogos/apu-logo-h-red.png";
import LogoHzWhNormPNG from "#images/APUlogos/apu-logo-h-white.png";
import LogoHzNormSVG from "#images/APUlogos/apu-logo-red.svg";
import LogoHzSplPNG from "#images/APUlogos/apu-logo-spl-h-red.png";
import LogoHzWhSplPNG from "#images/APUlogos/apu-logo-spl-h-white.png";
import LogoHzSplSVG from "#images/APUlogos/apu-logo-spl-red.svg";
import LogoStkNormPNG from "#images/APUlogos/apu-logo-s-red.png";
import LogoStkSplPNG from "#images/APUlogos/apu-logo-spl-s-red.png";
import LogoStkWhPNG from "#images/APUlogos/apu-logo-s-white.png";
import LogoStkWhSplPNG from "#images/APUlogos/apu-logo-spl-s-white.png";

// Set Logo to Normal or Special logo
const useSpecialLogo = false;
// Logo Object
export const APULogoObject = {
  hzSVG: useSpecialLogo ? LogoHzSplSVG : LogoHzNormSVG,
  hzPNG: useSpecialLogo ? LogoHzSplPNG : LogoHzNormPNG,
  hzWhPNG: useSpecialLogo ? LogoHzWhSplPNG : LogoHzWhNormPNG,
  stkPNG: useSpecialLogo ? LogoStkSplPNG : LogoStkNormPNG,
  stkWhPNG: useSpecialLogo ? LogoStkWhSplPNG : LogoStkWhPNG,
};
