import "./ImageWithButton.css";
import Button from "./Button";

const ImageWithButton = (props: {
  href: string;
  id: string;
  src: string;
  name: string;
}) => {
  return (
    <div className="container">
      <img className="image-with-button" src={props.src} alt={props.name} />
      <Button id={props.id} href={props.href} name={props.name} />
    </div>
  );
};

export default ImageWithButton;
