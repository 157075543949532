import "./ClickableIcon.css";

const displaySites = (props: {
  name: string;
  url: string;
  img: string;
  alt: string;
}) => {
  return (
    <div className="site">
      {props.name}
      <br></br>
      <a
        className="site-clickable"
        href={props.url}
        target="_blank"
        rel="noreferrer"
      >
        <img src={props.img} alt={props.alt} />
      </a>
    </div>
  );
};

export default displaySites;
