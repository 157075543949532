import "./URLTable.css";

/*
 * Takes a multidimensional array that consists of arrays listing urls and names.
 * Prints this array into a table with two columns.
 */

interface Link {
  url: string;
  name: string;
}

const URLTable = (props: { links: Array<Link> }) => {
  //Create array to store two rows before they're output onto the page.
  const tableRows = [];

  //Loop iterates through two objects in the array at a time and prints them.
  for (let i = 0; i < props.links.length; i = i + 2) {
    tableRows.push(
      <tr>
        <th key={i} className="cent">
          <h4>
            <a href={props.links[i].url} target="_blank" rel="noreferrer">
              {props.links[i].name}
            </a>
          </h4>
        </th>
        {props.links[i + 1] ? (
          <th key={i + 1} className="cent">
            <h4>
              <a href={props.links[i + 1].url} target="_blank" rel="noreferrer">
                {props.links[i + 1].name}
              </a>
            </h4>
          </th>
        ) : (
          <th className="cent">
            <h4></h4>
          </th>
        )}
      </tr>,
    );
  }

  return (
    <div id="tablebuild">
      <table>{tableRows}</table>
    </div>
  );
};

export default URLTable;
