import { useState } from "react";
import axios, { AxiosError } from "axios";
import { useQuery } from "react-query";
import { Interweave } from "interweave";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import $ from "jquery";
import uuid from "react-uuid";
import { apiURL } from "#components/ApiUrlConverter";

import "./BannerAdmin.css";
import TrashIcon from "#images/icons/trash-solid.svg";

interface BannerType {
  id: number;
  start_date: string;
  end_date: string;
  announcement_text: string;
  announcement_type: string;
}

const homeBannerEndpoint = apiURL("/api/v1/banners");

const BannerAdmin = () => {
  const { data: bannerData } = useQuery(
    "banners",
    () => axios.get(homeBannerEndpoint),
    {
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          window.location.reload();
        }
      },
      staleTime: Infinity,
    },
  );
  const [bannerMessageText, setBannerMessageText] = useState<
    string | undefined
  >();
  const [todayOnly, setTodayOnly] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  const [bannerLocation, setBannerLocation] = useState<string | undefined>();

  function handleBannerMessageTextChange(newValue: string) {
    setBannerMessageText(newValue);
  }

  function formatDate(date: Date): string {
    return date.toISOString().split("T")[0];
  }

  async function submitBannerMessage() {
    // Check if there is a banner message
    if (bannerMessageText == "" || bannerMessageText == "<p><br></p>") {
      alert("Please make sure you have entered a banner message!");
    } else if (
      !confirm(
        "Are you sure everything is inputted correctly? There is no turning back!",
      )
    ) {
      window.location.reload();
    } else if (bannerMessageText && startDate && endDate && bannerLocation) {
      // Data that will be sent to the database
      // Override will start as false, and will be checked after the initial post
      // First, generate formatted dates:
      const formInputs = {
        override: false,
        message_text: bannerMessageText,
        start_date: formatDate(startDate),
        end_date: formatDate(endDate),
        location: bannerLocation,
      };
      // Create bannerSuccess variable, to be used later
      let bannerSuccess = false;
      // Now, attempt to post the message, this could fail if there is a conflicting message
      try {
        const bannerPostResponse1 = await axios.post(
          homeBannerEndpoint,
          formInputs,
        );
        // If this first message was successful, update bannerSuccess
        bannerSuccess = bannerPostResponse1.status == 201;
      } catch (err: unknown) {
        if (err instanceof AxiosError) {
          // If the response is a 409, there is a conflict, so ask if the user wants to replace the conflicting banner
          if (err.response && err.response.status == 409) {
            const overrideMessage = `
              Do you wish to override the existing banner-mesage scheduled from 
              ${err.response.data[0].start_date} to ${err.response.data[0].end_date}
              that says: ${err.response.data[0].announcement_text}
            `;
            if (confirm(overrideMessage)) {
              const newFormInputs = {
                ...formInputs,
                override: true,
              };
              const bannerPostResponse2 = await axios.post(
                homeBannerEndpoint,
                newFormInputs,
              );
              // Now if the second message was successful, update update bannerSuccess
              bannerSuccess = bannerPostResponse2.status == 201;
            }
          }
        }
      }
      // Now, if the banner was posted on either the first or second try, offer to send the user to the new banner
      if (
        bannerSuccess &&
        confirm("Banner has been scheduled! Want to check it out?")
      ) {
        switch (bannerLocation) {
          case "dashboard_banner":
            window.location.href =
              "https://home.apu.edu/psp/PRODSTU/EMPLOYEE/SA/s/WEBLIB_HCX_GN.H_DASHBOARD.FieldFormula.IScript_Main?cmd=login&auth=cas";
            break;
          case "chapel":
            window.location.href =
              "https://home-services.apu.edu/services/chapel/index.php";
            break;
          case "trolley":
            window.location.href = "/trolley-tracker";
            break;
          default:
            window.location.reload();
            break;
        }
      } else {
        window.location.reload();
      }
    } else {
      alert(
        "Some fields are missing, be sure everything is filled out before submitting.",
      );
    }
  }

  async function deleteBanner(id: number) {
    if (confirm(`Are you sure you would like to delete the banner id ${id}?`)) {
      try {
        const bannerDeleteResponse = await axios.delete(
          `${homeBannerEndpoint}/${id}`,
        );
        alert(bannerDeleteResponse.data);
        window.location.reload();
      } catch (error) {
        alert(
          "There was an error deleting the banner. Try again or reach out for help if needed.",
        );
      }
    }
  }

  function renderBannerTime(startDate: string, endDate: string) {
    const todayDate = new Date();
    const today = formatDate(todayDate);
    if (endDate == null) {
      endDate = startDate;
    }
    startDate = startDate.split("T")[0];
    endDate = endDate.split("T")[0];
    if (startDate == endDate && today >= startDate && today <= endDate) {
      return (
        <p className="banner-messages-details" id="details-dates">
          <b>ACTIVE:</b>This banner-message is active for all of today,{" "}
          {endDate}.
        </p>
      );
    } else if (today >= startDate && today <= endDate) {
      return (
        <p className="banner-messages-details" id="details-dates">
          <b>ACTIVE:</b> This banner-message is currently active. It will be
          active from {startDate} to {endDate}.
        </p>
      );
    } else if (today > endDate && startDate == endDate) {
      return (
        <p className="banner-messages-details" id="details-dates">
          <b>PAST:</b> This banner-message was active on {endDate}.
        </p>
      );
    } else if (today < startDate && startDate == endDate) {
      return (
        <p className="banner-messages-details" id="details-dates">
          <b>UPCOMING:</b> This banner-message is scheduled to be active on{" "}
          {endDate}.
        </p>
      );
    } else if (today > endDate) {
      return (
        <p className="banner-messages-details" id="details-dates">
          <b>PAST:</b> This banner-message was active from {startDate} to{" "}
          {endDate}.
        </p>
      );
    } else if (today < startDate) {
      return (
        <p className="banner-messages-details" id="details-dates">
          <b>UPCOMING:</b> This banner-message is scheduled to be active from{" "}
          {startDate} to {endDate}.
        </p>
      );
    } else {
      return (
        <p className="banner-messages-details" id="details-dates">
          <b>UPCOMING:</b> This banner-message is presented from {startDate} to{" "}
          {endDate}.
        </p>
      );
    }
  }

  return (
    <section id="banner-admin">
      <body id="banner-form">
        <h1>Banner Message</h1>

        <p id="form-title">
          Please fill out form to post an announcement for users to see (i.e.
          down time, campus updates, security concerns, etc.).
          <br />
          <br />
          If you want to link to a phone number you{" "}
          <strong>must add "tel:+1..."</strong> in the link popup. So, if you
          want to add (626) 555-1234 as a phone number, you have to highlight
          the text, click the link icon, and in the popup type
          "tel:+16265551234". After this the link will work.
        </p>

        <form
          method="post"
          action="javascript:"
          id="announcement-form"
          onSubmit={() => submitBannerMessage()}
          className="banner-form-submit"
        >
          <div id="dates">
            <h2>What dates should this message display on?</h2>
            <div id="date-inputs">
              <div id="dates-start">
                <p>Start date:</p>
                <br />
                <input
                  type="date"
                  className="banner-dates"
                  id="start-date"
                  onChange={(e) => {
                    setStartDate(e.target.valueAsDate || undefined);
                  }}
                />
              </div>
              <div id="dates-end">
                <p>End date:</p>
                <br />
                <input
                  type="date"
                  className="banner-dates"
                  id="end-date"
                  onChange={(e) => {
                    setEndDate(e.target.valueAsDate || undefined);
                  }}
                />
              </div>
            </div>
            <div id="button-container" style={{ marginTop: 20 }}>
              <input
                id="today-only"
                type="checkbox"
                checked={todayOnly}
                className="radio-button"
                onChange={() => {
                  $("#start-date").prop("disabled", !todayOnly);
                  $("#start-date").css("opacity", !todayOnly ? "30%" : "100%");
                  $("#end-date").prop("disabled", !todayOnly);
                  $("#end-date").css("opacity", !todayOnly ? "30%" : "100%");
                  if (!todayOnly) {
                    const today = new Date();
                    setStartDate(today);
                    setEndDate(today);
                  }
                  setTodayOnly(!todayOnly);
                }}
              />
              <label htmlFor="today-only">Today ONLY?</label>
            </div>
          </div>
          <div id="announcement-locations">
            <h2>Where do you want this message to be?</h2>
            <div id="button-container">
              <input
                id="home-radio"
                type="radio"
                name="location"
                value="dashboard_banner"
                className="radio-button"
                required={true}
                onChange={(event) => {
                  setBannerLocation(event.target.value);
                }}
              />
              <label htmlFor="home-radio">Home</label>
            </div>
            <div id="button-container">
              <input
                id="chapel-radio"
                type="radio"
                name="location"
                value="chapel"
                className="radio-button"
                required={true}
                onChange={(event) => {
                  setBannerLocation(event.target.value);
                }}
              />
              <label htmlFor="chapel-radio">Chapel</label>
            </div>
            <div id="button-container">
              <input
                id="trolley-radio"
                type="radio"
                name="location"
                value="trolley"
                className="radio-button"
                required={true}
                onChange={(event) => {
                  setBannerLocation(event.target.value);
                }}
              />
              <label htmlFor="trolley-radio">Trolley Tracker</label>
            </div>
          </div>
          <ReactQuill
            theme="snow"
            value={bannerMessageText}
            onChange={handleBannerMessageTextChange}
            placeholder="Write your message here!"
            style={{ marginBottom: 15 }}
          />
          <div
            className="custom-alert banner"
            style={{ fontFamily: "sans-serif" }}
          >
            {bannerMessageText && bannerMessageText != "<p><br></p>" ? (
              <Interweave content={bannerMessageText} />
            ) : (
              "Message to display will appear here."
            )}
          </div>
          <br />
          <div id="submit">
            <input
              form="announcement-form"
              className="apu-button"
              id="submit-button"
              type="submit"
              value="Submit"
            />
          </div>

          <div id="banner-history">
            <h1 style={{ marginTop: 60 }}>Banner Schedule</h1>
            {bannerData?.data.map((banner: BannerType) => (
              <div
                className="banner-messages-card"
                id={String(banner.id)}
                key={uuid()}
              >
                <p
                  className="banner-messages-details"
                  id="details-id"
                  style={{ float: "left" }}
                >
                  <b>Banner ID: </b>
                  {banner.id}
                </p>
                <button onClick={() => deleteBanner(banner.id)}>
                  <img id="delete-banner" src={TrashIcon} alt="Delete Banner" />
                </button>
                <p>
                  <b>WHERE: </b>
                  {banner.announcement_type}
                </p>
                {renderBannerTime(banner.start_date, banner.end_date)}
                <Interweave
                  className="banner-message"
                  content={banner.announcement_text}
                />
              </div>
            ))}
          </div>
        </form>
      </body>
    </section>
  );
};

export default BannerAdmin;
