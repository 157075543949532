import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import Services from "#services/Services";
import BannerAdmin from "#services/banner-admin/BannerAdmin";
import CampusWorkOrder from "#services/campus-work-order/CampusWorkOrder";
import GoogleWorkspaceWidget from "#services/google-workspace-widget/GoogleWorkspaceWidget";
import TrolleyTracker from "#services/trolley-tracker/TrolleyTracker";
import Maintenance from "#services/maintenance/Maintenance";
import SSOTest from "#services/SSOTest";
import OneCard from "#services/one-card/OneCardPage";
import VIDOneCard from "#services/virtual-id-one-card/VIDOneCard";
import CASLoginErrorPage from "#services/cas-login-error/CASLoginErrorPage";
import ServiceCreditsTable from "#services/service-credits/ServiceCreditsTable";
import ServiceCreditsReport from "#services/service-credits/ServiceCreditsReport";
import ChapelAttendance from "#services/chapel-attendance/ChapelAttendance";

import "./App.css";
import ResourcePageMenu from "./services/resource-pages/ResourcePages";
import ResoucePages from "./services/resource-pages/ResourcePage";

// Forward domain directly to page
const RootDomain =
  location.hostname === "campusworkorder.apu.edu" ? CampusWorkOrder : Services;

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<RootDomain />} />
          <Route path="banner-admin" element={<BannerAdmin />} />
          <Route path="campus-work-order" element={<CampusWorkOrder />} />
          <Route
            path="google-workspace-widget"
            element={<GoogleWorkspaceWidget />}
          />
          <Route path="one-card" element={<OneCard />} />
          <Route path="trolley-tracker" element={<TrolleyTracker />} />
          <Route path="virtual-id-one-card" element={<VIDOneCard />} />
          <Route path="sso-test" element={<SSOTest />} />
          <Route path="service-credits" element={<ServiceCreditsTable />} />
          <Route
            path="service-credits-report"
            element={<ServiceCreditsReport />}
          />
          <Route path="chapel-attendance" element={<ChapelAttendance />} />
          <Route path="maintenance" element={<Maintenance />} />
          <Route path="cas-login-error" element={<CASLoginErrorPage />} />
          <Route path="resource-page-menu" element={<ResourcePageMenu />} />
          <Route path="resource-pages" element={<ResoucePages />} />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
