import ClickableIcon from "#components/ClickableIcon";
import WidgetContainer from "#components/WidgetContainer";

import "./GoogleWorkspaceWidget.css";
import gmail from "#images/gmail.jpeg";
import google_meeet from "#images/google_meet.png";
import google_calendar from "#images/googlecalendar.png";
import google_docs from "#images/googledocs.png";
import google_drive from "#images/googledrive.jpeg";
import google_slides from "#images/googleslides.png";

const GoogleWorkspaceWidget = () => {
  return (
    <WidgetContainer>
      <div id="top">
        <ClickableIcon
          name={"Gmail"}
          url={"https://mail.google.com/a/apu.edu"}
          img={gmail}
          alt={"Gmail"}
        />
        <ClickableIcon
          name={"Drive"}
          url={"https://drive.google.com/a/apu.edu"}
          img={google_drive}
          alt={"Drive"}
        />
        <ClickableIcon
          name={"Calendar"}
          url={"https://www.google.com/calendar/a/apu.edu"}
          img={google_calendar}
          alt={"Calendar"}
        />
      </div>

      <div id="bottom">
        <ClickableIcon
          name={"Meet"}
          url={"https://meet.google.com/"}
          img={google_meeet}
          alt={"Meet"}
        />
        <ClickableIcon
          name={"Docs"}
          url={"https://docs.google.com/a/apu.edu"}
          img={google_docs}
          alt={"Docs"}
        />
        <ClickableIcon
          name={"Slides"}
          url={"https://slides.google.com/a/apu.edu"}
          img={google_slides}
          alt={"Slides"}
        />
      </div>
    </WidgetContainer>
  );
};
export default GoogleWorkspaceWidget;
