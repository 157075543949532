//

import { TrolleyDataObject, TrolleyData } from "#types/trolley";

const foothillN = 34.1337;
const foothillS = 34.1333;
const citrusE = -117.8899;

const zone = [
  // East, Felix, Segerstrom, Soccer
  // 1 N Citrus: University to Foothill
  {
    NW: [foothillN, -117.8902],
    SE: [34.1308, citrusE],
  },
  // 2 Foothill: Palm to Citrus
  {
    NW: [foothillN, -117.8931],
    SE: [foothillS, citrusE],
  },
  // 3 Foothill: Odell to Palm
  {
    NW: [foothillN, -117.8942],
    SE: [foothillS, -117.8931],
  },
  // 4 Odell
  {
    NW: [34.1348, -117.8949],
    SE: [foothillN, -117.8939],
  },
  // 5 66: Soccer to Foothill
  {
    NW: [foothillN, -117.9001],
    SE: [foothillS, -117.8974],
  },
  // 6 Foothill: 66 to Odell
  {
    NW: [foothillN, -117.8974],
    SE: [foothillS, -117.8942],
  },
  // 7 University Dr
  {
    NW: [34.131, citrusE],
    SE: [34.1309, -117.8874],
  },
  // 8 Segerstom and Building 1 (short cut)
  {
    NW: [34.1341, -117.8962],
    SE: [foothillN, -117.8959],
  },
];

// Function to sum remaining stop ETA's
const sumETA = (locArray: Array<number>, stopNum: number) =>
  locArray.slice(stopNum, 4).reduce((acc: number, cur: number) => acc + cur, 0);

// Function to get smallest number in array
const shortestETA = (arr: Array<number>) =>
  arr.reduce((min, num) => Math.max(0, Math.min(min, num)), arr[0]);

const getLastStop = (lastStatus: string) => {
  // What Number stop was lastStauts
  let trolleyStop;
  switch (true) {
    case lastStatus.includes("East"):
      trolleyStop = lastStatus.includes("Arrive") ? [4, 3] : [0, 3];
      break;
    case lastStatus.includes("Felix"):
      trolleyStop = lastStatus.includes("Arrive") ? [1, 4] : [1, 0];
      break;
    case lastStatus.includes("Segerstrom"):
      trolleyStop = [2, 1];
      break;
    case lastStatus.includes("Soccer"):
      trolleyStop = [3, 2];
      break;
    default:
      trolleyStop = [0, 3];
  }
  return trolleyStop;
};

// Return true if number is between two numbers (order does not matter)
const isInRange = (num: number, [start, end]: number[]) => {
  return (num >= start && num <= end) || (num <= start && num >= end);
};

// Return what zone a trolley is in if at all
const getZoneNum = ([lat, lng]: number[]) => {
  let routeNumber = 0;
  zone.forEach(({ NW, SE }, route) => {
    if (isInRange(lat, [NW[0], SE[0]]) && isInRange(lng, [NW[1], SE[1]])) {
      routeNumber = route + 1;
    }
  });
  return routeNumber;
};

// Return ETA time adjustment
const getETAAdjustment = (lastStatus: string, lastLocation: number[]) => {
  let adjustETA = 0;
  const fromEast = lastStatus.includes("East");
  const fromFelix = lastStatus.includes("Felix");

  // Switch array of adjustments depending on where the trolley is coming from
  let adjustArray;
  if (fromEast) {
    adjustArray = [-1, -3, -4, -5, 0, 0, 0, 0];
  } else if (fromFelix) {
    adjustArray = [-9, -8, -7, -5, -6, -7, -10, -6];
  } else {
    adjustArray = [-4, -3, -2, 0, -1, -2, -5, 0];
  }

  // Get the zone the trolley is in
  const zone = getZoneNum(lastLocation);

  //
  switch (zone) {
    case 1: // 1 N Citrus: University to Foothill
      adjustETA = adjustArray[0];
      break;
    case 2: // 2 Foothill: Palm to Citrus
      adjustETA = adjustArray[1];
      break;
    case 3: // 3 Foothill: Odell to Palm
      adjustETA = adjustArray[2];
      break;
    case 4: // 4 Odell
      adjustETA = adjustArray[3];
      break;
    case 5: // 5 66: Soccer to Foothill
      adjustETA = adjustArray[4];
      break;
    case 6: // 6 Foothill: 66 to Odell
      adjustETA = adjustArray[5];
      break;
    case 7: // 7 University Dr and parking lot
      adjustETA = adjustArray[6];
      break;
    case 8: // 8 Segerstom and Building 1 (Sometimes Trolley takes a short cut)
      adjustETA = adjustArray[7];
      break;
  }

  return adjustETA;
};

// trolley ETA
const MapETA = (props: TrolleyData) => {
  // const [trolleyETA, setETA] = useState([0, 0]);

  // Trolley Stop Array Key
  // East = 0
  // Felix = 1
  // Seg = 2
  // Soc = 3
  // ETA Times in Minutes baseded on data from one trolley over one day
  const eastLoopMinETA = [5, 2, 3, 5, 0];
  const felixLoopMinETA = eastLoopMinETA
    .slice(1, 4)
    .concat(eastLoopMinETA[0])
    .concat(0);

  const trolleyETA = [];
  const eastETAArray: Array<number> = [];
  const westETAArray: Array<number> = [];

  props.trolleysData.forEach((data: TrolleyDataObject) => {
    const lastStatus = data.LastStatus;
    const lastLocation = [data.LastLocationLat, data.LastLocationLon];

    // What Number stop was lastStauts
    const trolleyStop = getLastStop(lastStatus);

    // Get the adjustment based on zone and directions
    const adjustETA = getETAAdjustment(lastStatus, lastLocation);

    // Add up ETA from each stop between last location and destination
    eastETAArray.push(sumETA(eastLoopMinETA, trolleyStop[0]) + adjustETA);
    westETAArray.push(sumETA(felixLoopMinETA, trolleyStop[1]) + adjustETA);
  });

  // ETA shortest time
  eastETAArray.length > 0 && trolleyETA.push(shortestETA(eastETAArray));
  westETAArray.length > 0 && trolleyETA.push(shortestETA(westETAArray));

  return (
    <>
      {trolleyETA && trolleyETA.length > 0 ? (
        <div className="trolley-eta">
          <div>Trolley Stop Estimate</div>
          <hr />
          <div className="table">
            <div className="tr">
              <div className="align-left td">(1) East Arrival</div>
              <div className="align-right td">{trolleyETA[0] ?? "n/a"} min</div>
            </div>
            <div className="tr">
              <div className="align-left td">(2) Felix Arrival</div>
              <div className="align-right td">{trolleyETA[1] ?? "n/a"} min</div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default MapETA;
