import { PropsWithChildren } from "react";
import "./WidgetContainer.css";

const WidgetContainer = (props: PropsWithChildren) => {
  return (
    <div id="workspace-widget">
      <div id="sites-container">{props.children}</div>
    </div>
  );
};

export default WidgetContainer;
