// Holloween Trolley Icons
export const hweenTrolleyIcon = (iconHex: string) => {
  return `<svg viewBox="0 0 18 18" version="1.1" id="svg3" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg">
  <defs
     id="defs3" />
  <g
     id="g3"
     transform="translate(-2.786379e-4,-0.00213112)">
    <path
       d="m 17.31,7.88 a 0.89,0.89 0 0 0 -1,-0.72 c -0.63,0 -1.45,0.1 -2.24,0.14 A 0.82,0.82 0 0 1 13.23,6.58 C 13,4.13 12.54,1.2 9.67,1.17 c -3.3,0 -4.75,3 -5.26,5.48 A 0.8,0.8 0 0 1 3.57,7.28 C 2.88,7.28 2.2,7.19 1.66,7.16 a 0.87,0.87 0 0 0 -0.6,1.59 16.27,16.27 0 0 0 2.72,1.56 0.81,0.81 0 0 1 0.47,0.67 24.58,24.58 0 0 1 -0.42,4.19 0.82,0.82 0 0 0 1.3,0.78 2.58,2.58 0 0 1 1.28,-0.59 c 1.08,0 1.89,1.56 3.15,1.47 1.26,-0.09 2,-1.68 3.12,-1.73 a 2.05,2.05 0 0 1 1.12,0.31 0.82,0.82 0 0 0 1.2,-0.94 c -0.36,-1.34 -0.71,-2.79 -0.89,-3.32 a 0.79,0.79 0 0 1 0.42,-1 21.07,21.07 0 0 0 2.44,-1.44 0.88,0.88 0 0 0 0.34,-0.83 z M 9.68,8"
       stroke="#ffffff"
       stroke-width="1.33px"
       id="path1"
       style="stroke-width:3.27459;stroke-dasharray:none"
       transform="matrix(0.90181563,0,0,0.930703,0.88362498,0.62381934)" />
    <path
       d="m 17.31,7.88 a 0.89,0.89 0 0 0 -1,-0.72 c -0.63,0 -1.45,0.1 -2.24,0.14 A 0.82,0.82 0 0 1 13.23,6.58 C 13,4.13 12.54,1.2 9.67,1.17 c -3.3,0 -4.75,3 -5.26,5.48 A 0.8,0.8 0 0 1 3.57,7.28 C 2.88,7.28 2.2,7.19 1.66,7.16 a 0.87,0.87 0 0 0 -0.6,1.59 16.27,16.27 0 0 0 2.72,1.56 0.81,0.81 0 0 1 0.47,0.67 24.58,24.58 0 0 1 -0.42,4.19 0.82,0.82 0 0 0 1.3,0.78 2.58,2.58 0 0 1 1.28,-0.59 c 1.08,0 1.89,1.56 3.15,1.47 1.26,-0.09 2,-1.68 3.12,-1.73 a 2.05,2.05 0 0 1 1.12,0.31 0.82,0.82 0 0 0 1.2,-0.94 c -0.36,-1.34 -0.71,-2.79 -0.89,-3.32 a 0.79,0.79 0 0 1 0.42,-1 21.07,21.07 0 0 0 2.44,-1.44 0.88,0.88 0 0 0 0.34,-0.83 z M 9.68,8"
       stroke="#000000"
       stroke-width="0.506019px"
       fill=${iconHex}
       id="path2"
       transform="matrix(0.90181563,0,0,0.930703,0.88362498,0.62381934)" />
    <ellipse
       cx="10.42"
       cy="5.27"
       rx="0.74000001"
       ry="1.21"
       stroke="#000000"
       stroke-width="0.545765px"
       fill="#ffffff"
       id="ellipse2"
       transform="matrix(0.90181563,0,0,0.930703,0.88362498,0.62381934)" />
    <ellipse
       cx="7.48"
       cy="5.27"
       rx="0.74000001"
       ry="1.21"
       stroke="#000000"
       stroke-width="0.545765px"
       fill="#ffffff"
       id="ellipse3"
       transform="matrix(0.90181563,0,0,0.930703,0.88362498,0.62381934)" />
    <circle
       cx="8.9499998"
       cy="7.9499998"
       r="0.74000001"
       stroke="#000000"
       stroke-width="0.545765px"
       fill="#ffffff"
       id="circle3"
       transform="matrix(0.90181563,0,0,0.930703,0.88362498,0.62381934)" />
  </g>
</svg>`;
};
