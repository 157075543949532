import Snowfall from "react-snowfall";

interface SnowColor {
  snowColorID: number;
}

export const chmasTrolleyIcon = (iconHex: string) => {
  return `<svg data-name="Layer 1" version="1.1" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
  <defs>
    <style>.cls-1{fill:none;stroke:#fff;stroke-width:4px;}.cls-1,.cls-2{stroke-miterlimit:10;}.cls-2{fill:#3244c9;stroke:#000;}</style>
  </defs>
  <title>trolley_mark_COLOR</title>
  <g transform="matrix(.08591 0 0 .085815 .030145 -4.7734)">
    <path d="m145.2 56.944c-12.5 0.52885-24.364 4.1073-35.079 9.5363-4.2363-0.27087-7.0878 0.17699-8.139 0.1138a16.16 16.16 0 0 0-0.95485-0.02276c-0.2885-1.28e-4 -0.64965 0.039654-0.95485 0.04552-11.891-6.0468-25.315-9.974-39.604-9.6274a16.16 16.16 0 0 0-0.18188 0c-7.9314 0.2865-17.536 2.2392-25.667 9.5136a16.16 16.16 0 0 0-0.25008 0.20484c-5.1615 4.807-8.234 10.943-9.1166 17.479-13.591 3.6177-21.974 15.677-20.916 29.497 0.018677 9.6155-0.18477 19.652 0.18188 30.088a16.16 16.16 0 0 0 0.22735 2.1622c1.0139 5.8763 3.7907 10.83 7.6161 14.771-0.024347 25.407-0.082621 50.84 0.068204 76.336a16.16 16.16 0 0 0 0.068203 1.1608c1.2444 15.803 13.98 26.545 29.305 25.946 10.088-0.08852 20.343 0.00798 30.737-0.02276 32.612-3e-3 65.239 0.00681 97.872-0.04552a16.16 16.16 0 0 0 1.2504-0.06828c15.86-1.263 26.849-14.103 26.008-29.633v-0.15931c0.00448-24.425-0.00403-48.862 0-73.286 5.5763-5.6134 8.563-13.341 7.9798-21.804-0.01317-9.6715 0.20241-19.744-0.15914-30.202a16.16 16.16 0 0 0-0.18188-1.8891c-1.8245-11.891-10.073-20.468-20.711-23.147-0.0356-0.24132-0.02652-0.46216-0.0682-0.70555a16.16 16.16 0 0 0-0.02273-0.091039c-1.7956-10.162-8.3412-17.941-17.233-21.963-7.968-3.8829-15.638-4.2689-21.621-4.1878l0.15914 0.15932a16.16 16.16 0 0 0-0.61383-0.15932z" fill="#ffffff" style="paint-order:normal"/>
    <path transform="matrix(.94224 .33494 -.25454 .96706 .41054 0)" d="m80.338 53.742a32.877 15.26 0 0 0-12.863 12.107 32.877 15.26 0 0 0 32.877 15.26 32.877 15.26 0 0 0 30.116-9.1477 14.607 34.348 51.147 0 0 29.433-11.476 14.607 34.348 51.147 0 0 19.711-33.474 14.607 34.348 51.147 0 0-34.791 8.1761 14.607 34.348 51.147 0 0-18.567 21.276 32.877 15.26 0 0 0-25.902-5.8746 32.877 15.26 0 0 0-20.014 3.1534z" fill="#ee4045" style="paint-order:normal"/>
    <path transform="matrix(.86244 .29661 -.44484 .71833 8.3068 17.46)" d="m97.426 56.792a27.528 9.657 0 0 0-6.9232 6.4039 27.528 9.657 0 0 0 27.528 9.6567 27.528 9.657 0 0 0 27.301-8.4432 10.256 25.919 44.795 0 0 24.904-13.113 10.256 25.919 44.795 0 0 9.6482-25.008 10.256 25.919 44.795 0 0-26.074 12.477 10.256 25.919 44.795 0 0-11.417 19.939 27.528 9.657 0 0 0-24.362-5.1651 27.528 9.657 0 0 0-20.605 3.2531z" fill="#ffffff" style="paint-order:normal"/>
  <rect x="34.303" y="142.37" width="141.39" height="99.781" ry="6.6022" fill=${iconHex} style="paint-order:normal"/>
  <rect x="26.297" y="105.04" width="157.41" height="42.553" ry="6.6022" fill=${iconHex} style="paint-order:normal"/>
  <rect x="26.297" y="105.04" width="157.41" height="42.553" ry="6.6022" fill="#000000" fill-opacity=".3" style="paint-order:normal"/>
    <path d="m99.887 88.553c-3.6698 0-6.6244 2.9541-6.6244 6.6239v9.726h-4.2612v137.24h31.996v-137.24h-4.2612v-9.726c0-3.6698-2.9546-6.6239-6.6244-6.6239h-10.225z" fill="#ee4045" style="paint-order:normal"/>
  </g>
</svg>`;
};

export const chmasTrolleyIcon2 = (iconHex: string) => {
  return `<svg data-name="Layer 1" version="1.1" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <circle cx="9" cy="9" r="6.5" fill="none" stroke="#fff" stroke-width="4px" />
    <circle cx="9" cy="9" r="6.5" stroke-miterlimit="10" fill=${iconHex} stroke="#000" />
    <circle cx="9" cy="9" r="2.7988" stroke-width="1.3994"/>
    <g fill="#ffcc00" stroke-width="0">
      <circle cx="14.458" cy="9.0321" r=".59192"/>
      <circle cx="11.618" cy="11.3" r=".86316"/>
      <circle cx="5.8998" cy="12.395" r=".65619"/>
      <circle cx="3.3454" cy="9.8316" r=".91779"/>
    </g>
    <circle cx="10.314" cy="14.576" r=".62646" fill="#ffd42a" stroke-width="0"/>
    <g transform="matrix(1.24 0 0 1.24 -1.921 -2.1072)" fill="#800000" stroke-width="0">
      <path d="m9.3641 4.8432c1.3096-1.8776 2.2049-2.3297 2.9903-2.0866 0.78545 0.24311 0.64432 2.713 0.64432 2.713s0.14634 2.1452-0.95388 2.0233c-1.1002-0.12198-2.6492-1.6749-2.6492-1.6749z"/>
      <path d="m8.2507 4.8408c-1.3096-1.8776-2.2049-2.3297-2.9903-2.0866-0.78545 0.24311-0.64432 2.713-0.64432 2.713s-0.14634 2.1452 0.95388 2.0233c1.1002-0.12198 2.6492-1.6749 2.6492-1.6749z"/>
    </g>
    <g transform="translate(-.021514)" fill="#800000" stroke-width="0">
      <path d="m8.8885 5.5314 1.2295-0.4096 0.91169 2.7365-0.89169-0.62653-0.33777 1.0361z"/>
      <path d="m9.1546 5.5293-1.2295-0.4096-0.9117 2.7365 0.89169-0.62653 0.33777 1.0361z"/>
    </g>
    <ellipse cx="9" cy="4.5881" rx="1.5929" ry="1.6876" fill="#a8353a" stroke-width=".80593"/>
    </svg>`;
};

const ChristmasTheme = (props: SnowColor) => {
  let snowColor;
  switch (props.snowColorID) {
    case 1:
      snowColor = "#C0C0C0";
      break;
    case 2:
      snowColor = "#B2BEB5";
      break;
    case 3:
      snowColor = "#999999";
      break;
    case 4:
      snowColor = "#A9A9A9";
      break;
    default:
      snowColor = "#7393B3";
  }
  return (
    <div id="snow">
      <Snowfall color={snowColor} snowflakeCount={200} />
    </div>
  );
};

export default ChristmasTheme;
