import { OneCardPatron } from "#types/OneCard";
import uuid from "react-uuid";

import Button from "#components/Button";
import CougarBucksIcon from "#images/icons/cougar-bucks-red.svg";

import "./DisplayCard.css";
import React from "react";

// Interface types for objects
interface OneCardPatronProps {
  patronData?: {
    data: OneCardPatron;
  };
}

interface DisplayCardProps {
  hasAccount?: boolean;
  isStudent?: boolean;
  hasDiningPlan?: boolean;
  patronData?: {
    data: OneCardPatron;
  };
}

interface MealTypes {
  durMeals?: string[];
}

const JoinWithAnd = (props: MealTypes) => {
  const durMeals = props.durMeals ?? [];
  return (
    <>
      {durMeals.map((meal: string, index: number) => (
        <React.Fragment key={uuid()}>
          {!!index && (index === durMeals.length - 1 ? " and " : ", ")}
          <b>{meal}</b>
        </React.Fragment>
      ))}
    </>
  );
};

const DiningPlan = (props: OneCardPatronProps) => {
  const meals = [];
  let mealDuration = "weekly";
  // Plan C+ and G are not weekly like the others
  const semesterPlan = /\b(C\+|G)\s*$/.exec(
    props.patronData?.data.planName ?? "",
  );
  if ((semesterPlan?.length ?? 0) > 0) {
    mealDuration = "semester";
  }

  // Check if there are meals
  if (props.patronData?.data.mealsBalance) {
    meals.push({
      type: "My Meals",
      balance: props.patronData?.data.mealsBalance,
      duration: mealDuration,
    });
  }
  // Check if there are guest meals
  if (props.patronData?.data.guestMealsBalance) {
    //
    meals.push({
      type: "Guest Meals",
      balance: props.patronData?.data.guestMealsBalance,
      duration: "semester",
    });
  }
  // Check if there are flex dollars
  if (props.patronData?.data.flexDollarsBalance) {
    meals.push({
      type: "Flex Dollars",
      balance: "$" + props.patronData?.data.flexDollarsBalance,
      duration: "semester",
    });
  }

  // List and join meals strings for wording
  const weeklyMeals: string[] = [];
  const semesterMeals: string[] = [];

  meals.forEach((meal) => {
    if (meal.duration === "weekly") {
      weeklyMeals.push(meal.type);
    } else if (meal.duration === "semester") {
      semesterMeals.push(meal.type);
    }
  });

  // Create wording for when meals/dollars reset
  const mealWeekString = (
    <>
      <JoinWithAnd durMeals={weeklyMeals} /> will reset to{" "}
      <b>{props.patronData?.data.diningMealsMax}</b> each Sunday.{" "}
    </>
  );

  const mealSemString = (
    <>
      <JoinWithAnd durMeals={semesterMeals} /> are for the whole semester.
    </>
  );

  return (
    <div className="account">
      <h1 id="name-header">Meal Plan</h1>
      <hr />
      <h1 className="account-name-dining">{props.patronData?.data.planName}</h1>
      <div className="current-balance">
        <div id="table">
          {meals.map((meal) => (
            <React.Fragment key={uuid()}>
              <div className="td">
                <div className="align-left">{meal.type}:</div>
                <div className="align-right">{meal.balance}</div>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>

      <div id="dining-points-difference">
        {weeklyMeals.length > 0 ? mealWeekString : ""}
        {weeklyMeals.length > 0 && semesterMeals.length > 0 ? <br /> : ""}
        {semesterMeals.length > 0 ? mealSemString : ""}
      </div>
    </div>
  );
};

const CougarBucks = (props: OneCardPatronProps) => {
  return (
    <div className="account">
      <div className="cougar-bucks">
        <img className="image" src={CougarBucksIcon} alt="Cougar Bucks Icon" />
        <h1>Cougar Bucks</h1>
        <p className="current-balance" id="cougar-bucks-detail">
          ${props.patronData?.data.cougarBucksBalance}
        </p>
      </div>
      <Button
        id="add-cougar-bucks"
        href="https://get.cbord.com/apu/full/login.php"
        name="Add Cougar Bucks"
      />
    </div>
  );
};

const NoDiningPlan = () => {
  return (
    <div className="account">
      <div className="no-plan">
        <h1>Student Meal Plan</h1>
        <p className="expected-balance">
          No meal plan?
          <br />
          No problem!
        </p>
        <Button
          id="add-dining-points"
          href="https://formstack.apu.edu/forms/dining_plan_sign_up"
          name={["Get a Meal Plan"]}
        />
      </div>
    </div>
  );
};

const NoAccounts = () => {
  return (
    <div className="account">
      <div className="no-plan">
        <h1>One Card</h1>
        <p className="balance-detail" id="dining-points-detail">
          You do not have a One Card account.
          <br />
          If you feel that you have received this message in error, please
          refresh this page.
          <br />
          If the problem persists contact the IT Support Center.
        </p>
        <Button
          id="support-link"
          href="https://support.apu.edu/new"
          name="Contact Support"
        />
      </div>
    </div>
  );
};

const DisplayCard = (props: DisplayCardProps) => {
  let cardContents;
  // Compile Card Contents based on user

  // No account
  if (!props.hasAccount) {
    return (
      <section className="onecard">
        <NoAccounts />
      </section>
    );
  }

  // User has dining Plan or is Student
  if (props.hasDiningPlan) {
    cardContents = <DiningPlan patronData={props.patronData} />;
  } else if (props.isStudent) {
    cardContents = <NoDiningPlan />;
  }

  // Set card contents with cougar bucks
  return (
    <section className="onecard">
      {cardContents} <CougarBucks patronData={props.patronData} />
    </section>
  );
};

export default DisplayCard;
