export const gradTrolleyIcon = (iconHex: string) => {
  return `<svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <g id="g1">
      <path id="path9"
        style="fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:1.93814;stroke-linecap:round;stroke-linejoin:miter;stroke-dasharray:none;stroke-opacity:1"
        d="m 2.8476562,-1.9609375 c -1.7557305,0.1990061 -3.37548124,1.00560571 -5.0649472,1.48676592 -2.9775283,1.01914993 -5.9931689,1.93540828 -8.946771,3.01909348 -0.764524,0.3869395 -1.46912,0.9660204 -1.844886,1.7175113 -0.382158,0.764273 -0.42848,1.7090423 -0.271733,2.5490352 0.121004,0.6484504 0.429923,1.3024108 0.905338,1.7596967 1.163603,1.1192311 3.3581074,0.8049462 4.389804,2.0468209 0.3365336,0.405092 0.3272779,1.007638 0.3938433,1.530059 0.1582576,1.242044 0.00381,2.504168 0.00576,3.756252 3.5713066,1.317241 7.14253163,2.634707 10.7148437,3.949219 2.679211,-0.950974 5.3423652,-1.992883 8.031641,-2.886719 H 21.328127 C 20.807111,15.099457 20.234437,13.240874 19.71875,11.369141 19.435835,10.342274 19.557848,9.1525447 18.904297,8.2792969 19.50734,7.709412 19.753988,6.8974991 19.75,6.0429688 19.746012,5.1884385 19.612582,4.5167746 18.671875,3.5488281 17.731168,2.5808816 15.746141,2.0631496 14.245212,1.4526026 10.908193,0.36710936 7.6035672,-0.83066138 4.2459816,-1.8452268 3.7909105,-1.9490942 3.3129896,-2.0033034 2.8476562,-1.9609375 Z"
        transform="matrix(0.5276733,0,0,0.5276733,7.0594948,3.0512751)" />
      <path style="fill:none;stroke:#000000;stroke-width:1.92169;stroke-linecap:round;stroke-linejoin:miter;stroke-dasharray:none;stroke-opacity:1"
        d="M 15.614676,6.1483874 V 10.24882"
        id="path6"
        transform="matrix(0.53219034,0,0,0.53219034,7.3415202,3.0553835)" />
      <path d="m 15.652344,10.214844 c -0.156265,-6e-6 -0.313498,0.102273 -0.3125,0.30664 8.4e-5,0.10727 0.05545,0.206929 0.146484,0.263672 l -0.34375,1.160157 h 1.019531 l -0.34375,-1.160157 c 0.09029,-0.05722 0.14487,-0.156791 0.144532,-0.263672 10e-4,-0.204344 -0.154282,-0.306635 -0.310547,-0.30664 z"
        style="display:inline;fill:${iconHex};stroke:#000000;stroke-width:1.22724;stroke-linecap:square;stroke-linejoin:miter;stroke-miterlimit:1.7;stroke-dasharray:none;stroke-opacity:1;paint-order:markers stroke fill"
        id="path8"
        transform="translate(0,-1.6963416)" />
      <path style="display:inline;fill:${iconHex};fill-opacity:1;stroke:#000000;stroke-width:1.0227;stroke-linecap:butt;stroke-linejoin:round;stroke-dasharray:none;stroke-opacity:1"
        d="m 4.699761,6.8376916 h 8.04106 v 3.4163144 l -4.05138,1.474428 -3.9870746,-1.467776 z"
        id="path2" />
      <path style="color:#000000;fill:#000000;stroke-width:0.508765;stroke-linejoin:round;stroke-miterlimit:0.5;stroke-dasharray:none;paint-order:markers stroke fill"
        d="M 1.9921875,3.9570313 0.16992187,5.7734375 c -0.2007684,0.1990523 -0.2007684,0.5236039 0,0.7226562 L 1.9921875,8.3085938 c 0.3569136,0.3560973 0.3561249,0.3617957 0.71875,0 L 4.5273437,6.5019531 C 4.7273423,6.3036607 4.7282177,5.9806235 4.5292969,5.78125 L 2.7128906,3.9589844 C 2.3467441,3.605915 2.3449001,3.6051194 1.9921875,3.9570313 Z M 2.3496094,5.0371094 3.4472656,6.1386719 2.3515625,7.2304687 1.25,6.1347656 Z"
        id="path3"
        transform="matrix(3.4814624,0,0,1.1606559,0.50593924,-0.97552403)" />
      <path style="color:#000000;fill:${iconHex};stroke-width:0.508765;stroke-linejoin:round;stroke-miterlimit:0.5;stroke-dasharray:none;paint-order:markers stroke fill"
        d="M 4.1682058,6.1410808 2.3515114,7.9469815 0.5286545,6.1347171 2.351612,4.3181233 Z"
        id="path7"
        transform="matrix(3.4814624,0,0,1.1606559,0.50593924,-0.97552403)" />
    </g>
  </svg>`;
};
