import React, { useState, useEffect } from "react";
import MaptilerLeaflet from "./MaptilerLeaflet";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { useQuery } from "react-query";
import "./TrolleyTracker.css";

import { APULogoObject } from "#components/APULogo";
import { apiURL } from "#components/ApiUrlConverter";

interface Counting {
  getTrolleyData: () => void;
  lastUpdatedTime: string;
}

// Timer Component
const Timer = (props: Counting) => {
  const [countUpdate, setCountUpdate] = useState(false);
  const [count, setCount] = useState(0);

  // Countdown timer to retrive updated data
  const updateTimer = () => {
    if (count <= 0) {
      setCount(5);
      props.getTrolleyData();
    } else {
      setCount((currentCount) => currentCount - 1);
    }
    setTimeout(() => setCountUpdate(!countUpdate), 1000);
  };

  useEffect(() => {
    updateTimer();
  }, [countUpdate]);

  return (
    <div id="tracking-info">
      <div className="vert-bar">Updated {props.lastUpdatedTime}</div>
      <div>
        Refresh in{" "}
        {count.toLocaleString(undefined, { minimumIntegerDigits: 2 })}
      </div>
    </div>
  );
};

// Main Component
const TrolleyTracker = () => {
  // Get url params
  const [page, ,] = useSearchParams();
  const devMode = Boolean(page.get("dev") ?? false);
  const devBanner = Boolean(page.get("banner") ?? false);

  const [mapDefaultZoom, setMapDefaultZoom] = useState(15);
  const [lastUpdatedTime, setLastUpdatedTime] = useState("");

  let bannerMessage = "";
  const sorryMessage = "Sorry! Trolley Tracker is currently out of service!";

  // endpoint for devmode
  const endpoint = devMode
    ? apiURL("/api/public/trolleytracker-dev")
    : apiURL("/api/public/trolleytracker");

  const now = new Date();

  // ---- Test banner (commented out)
  // Tuesday Gap
  // const now = new Date(2023, 10, 28);
  // now.setHours(15, 1, 0);
  // Weekend
  // const now = new Date(2023, 10, 25);
  // Weekday After Hours
  // const now = new Date(2023, 10, 22);
  // now.setHours(23, 1, 0);
  // Friday After Hours
  // const now = new Date(2023, 10, 24);
  // now.setHours(19, 1, 0);
  // ---- End Test Banner

  // Trolley Service Times:
  //  Monday-Thursday: 6:45am - 11:00pm
  //  Friday: 6:45am - 7:00pm
  //  No Trolley Service on Tuesday: 3:00pm - 3:45pm
  const beginHours = new Date(now);
  const afterHours = new Date(now);
  const afterFridayHours = new Date(now);
  const beginGapTuesday = new Date(now);
  const stopGapTuesday = new Date(now);
  // The % returns only the remainder after division (0 and 6 are the only two out of 0-6 without a remainder when divided by 6: 0 = Sun, 6 = Sat)
  const isWeekend = now.getDay() % 6 === 0;
  // Set hours
  beginHours.setHours(6, 45, 0);
  afterHours.setHours(23, 0, 0);
  afterFridayHours.setHours(19, 0, 0);
  beginGapTuesday.setHours(15, 0, 0);
  stopGapTuesday.setHours(15, 45, 0);

  // Set banner for Known down time
  // Tuesday from 3:00pm - 3:45pm
  if (now.getDay() === 2 && now > beginGapTuesday && now < stopGapTuesday) {
    bannerMessage = `Sorry! There are currently no operating trolleys! Trolley Service will be back after 3:45pm!`;
    // Weekday afterhours
  } else if (now > afterHours || now < beginHours) {
    bannerMessage = `Sorry! There are currently no operating trolleys! Trolley Service will start at 6:45am!`;
  }
  // Weekend or Friday evening
  if (isWeekend || (now.getDay() === 5 && now > afterFridayHours)) {
    bannerMessage = `Sorry! There are currently no operating trolleys! Trolley Service will start at 6:45am!`;
  }

  // Test banner
  if (devBanner) {
    bannerMessage = `DEV MODE: ${now} Sorry! There are currently no operating trolleys! Trolley Service will start at 6:45am!`;
  }

  // If there is no current message to show, message will be empty and the alert will not be shown
  const closeAlertMessage = () => {
    // setMessage("");
    bannerMessage = "";
  };

  // Setup axios connection
  const api = axios.create({
    baseURL: endpoint,
    timeout: 1000,
  });

  // Attempt to handle 401 auth errors
  api.interceptors.response.use(undefined, (error) => {
    if (error.status === 401) {
      window.location.reload();
    }
    return Promise.reject(new Error(error));
  });

  // Retrive location/other trolley data
  const fetchTrolleyData = async () => {
    const res = await api
      .get(`/`, {
        validateStatus: function (status) {
          return status < 300;
        },
      })
      .then((res) => {
        if (bannerMessage === sorryMessage) {
          // setMessage("");
          bannerMessage = "";
        }
        // setCallTrolleyAPI(false);
        setLastUpdatedTime(now.toLocaleTimeString());
        return res.data;
      })
      .catch(() => {
        // Temporary message while trolley tracker is out of service
        if (bannerMessage === "") {
          // setMessage(sorryMessage);
          bannerMessage = sorryMessage;
        }
        setLastUpdatedTime(now.toLocaleTimeString());
      });

    return res;
  };

  // Fetch trolley data on demand
  const { data: trolleyData, refetch } = useQuery(
    "trolleyData",
    fetchTrolleyData,
    {
      refetchOnWindowFocus: false,
      enabled: false, // disable this query from automatically running
    },
  );

  // Handler function to refreash trolley data
  const handleTrolleyFetch = () => {
    // manually refetch
    refetch();
  };

  // Get screen resolution
  useEffect(() => {
    // window.onload
    const windowSizeHandler = () => {
      // Change zoom level based on resolution
      if (window.innerWidth < 380 || window.innerHeight < 330) {
        setMapDefaultZoom(14.5);
      } else if (
        (window.innerWidth > 379 && window.innerWidth < 730) ||
        window.innerHeight < 450
      ) {
        setMapDefaultZoom(15);
      } else {
        setMapDefaultZoom(15.5);
      }
    };

    // Set zoom on first window load
    window.onload = windowSizeHandler;

    // Set event listener for window resize
    window.addEventListener("resize", windowSizeHandler);

    return () => {
      window.removeEventListener("resize", windowSizeHandler);
    };
  }, []);

  return (
    <div id="trolley-tracker">
      {/* Conditionally show an alert banner-message with a $message string (currently undefined) */}
      {/* {message.length > 1 && message !== "HIDDEN" ? ( */}
      {bannerMessage.length > 1 ? (
        <div className="custom-alert banner">
          <button
            id="closebtn"
            onClick={closeAlertMessage}
            onKeyDown={closeAlertMessage}
          >
            &#xD7;
          </button>
          <p>{bannerMessage}</p>
        </div>
      ) : null}
      <img id="apu-logo" alt="APU Logo" src={APULogoObject.hzPNG} />

      {/* The div used by Leaflet and MapTiler to create the map */}
      <MaptilerLeaflet
        trolleysDataRaw={trolleyData}
        mapDefaultZoom={mapDefaultZoom}
      />

      {/* Additional information regarding trolley data and schedules */}
      <Timer
        getTrolleyData={handleTrolleyFetch}
        lastUpdatedTime={lastUpdatedTime}
      />
    </div>
  );
};

export default TrolleyTracker;
