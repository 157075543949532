import React from "react";

import "./CASLoginErrorPage.css";

const CASLoginErrorPage = () => {
  return (
    <section id="cas-login-error">
      <div id="center">
        <p className="oops">Oops!</p>
        <p className="oops-detail">
          You've reached a page which requires an APU Login via CAS, but you
          aren't logged in!
        </p>
        <p className="oops-detail">
          Please log in at{" "}
          <a href="https://cas.apu.edu/cas/login">cas.apu.edu</a>.
        </p>
        <p className="oops-detail">
          If you continue to receive this error, please contact the IMT Support
          Center.
        </p>
        <p className="oops-detail">
          Online at{" "}
          <a href="https://support.apu.edu/hc/en-us/requests/new">
            support.apu.edu
          </a>
        </p>
        <p className="oops-detail">
          By email at <a href="mailto:support@apu.edu">support@apu.edu</a>
        </p>
        <p className="oops-detail">
          Or by phone at <a href="tel:+16268155050">(626) 815-5050</a>
        </p>
      </div>
    </section>
  );
};
export default CASLoginErrorPage;
