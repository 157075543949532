import React, { useState } from "react";
import { Buffer } from "buffer";
import axios, { AxiosError } from "axios";
import { useQuery } from "react-query";

import { defaultProfilePicture } from "#images/defaultProfilePicture";
import { apiURL } from "#components/ApiUrlConverter";

const endpoint = apiURL("/api/v1/users/profile");

const SSOTest = () => {
  const {
    isSuccess,
    isError,
    data: userData,
  } = useQuery("userProfile", () => axios.get(endpoint), {
    onError: (error: AxiosError) => {
      if (error?.response?.status === 401) {
        window.location.reload();
      }
    },
    onSuccess: (userData) => {
      const image = Buffer.from(userData.data.PHOTO.data).toString("base64");
      const imageSrc = `data:image/jpeg;base64,${image}`;
      setProfilePicture(imageSrc);
    },
  });
  const [profilePicture, setProfilePicture] = useState(defaultProfilePicture);

  if (isError) {
    return <span>Error!</span>;
  }

  if (isSuccess)
    return (
      <div>
        <header>
          <h1>SSO Test Page</h1>
        </header>
        <p>Hello {userData.data.NAME}!</p>
        <p>Your netid is: {userData.data.OPRID}</p>
        <p>Your emplid is: {userData.data.EMPLID}</p>
        <p>Your personas: {userData.data.PERSONAS}</p>
        <p>Your career: {userData.data.ACAD_CAREER_DESCR}</p>
        <p>Your academic plans: {userData.data.ACAD_PLANS_DESCR}</p>
        <p>Your OneCard Number: {userData.data.onecardNumber} </p>
        <p>Your job title: {userData.data.jobtitle}</p>
        <p>Your department: {userData.data.department}</p>
        <img src={profilePicture} alt="" />
      </div>
    );

  return <span>Loading....</span>;
};

export default SSOTest;
