import React from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { Interweave } from "interweave";
import { useSearchParams } from "react-router-dom";
import uuid from "react-uuid";
import Accordion from "#components/Accordion";
import "./ResourcePage.css";
import { AccordionDirType, AccordionContent } from "#types/ResourcePage";
import { apiURL } from "#components/ApiUrlConverter";

//
const resourceEndpoint = apiURL("/api/public/resourcelinks");
// Banner Vars
const homeBannerEndpoint = apiURL("/api/public/banners");

const Banner = () => {
  // Call Banner API
  // Call Home Service api for an active home banner
  const { isSuccess: bannerIsSuccess, data: bannerData } = useQuery(
    "homeBanner",
    () => axios.get(homeBannerEndpoint),
    {
      staleTime: Infinity,
    },
  );

  return (
    <>
      {bannerIsSuccess && (
        <div id="banner" className="banner-div">
          <div
            id="banner-message-text"
            className="banner-text"
            style={{ margin: "auto 0px" }}
          >
            <Interweave content={bannerData.data.announcement_text} />
          </div>
          <button
            id="banner-close"
            className="banner-x-button"
            onClick={() => {
              const banner = document.getElementById("banner");
              if (banner) {
                banner.style.display = "none";
              }
            }}
          >
            &#xD7;
          </button>
        </div>
      )}
    </>
  );
};

// Expand ad-hoc links for top or bottom of accordion
const AddLink = ({
  data,
}: Readonly<{
  data: AccordionDirType;
}>) => {
  const directLink = data.beforeLink || data.afterLink;
  if (directLink) {
    return (
      <div
        className="panel-resources direct-link"
        style={{ maxHeight: "100%" }}
      >
        {data.afterLink && <hr />}
        {directLink.map((item: AccordionContent) => (
          <ul id="url" key={uuid()}>
            <li key={uuid()}>
              <a
                target="_blank"
                id="resource-url"
                key={uuid()}
                href={item.link}
              >
                {item.item}
              </a>
            </li>
          </ul>
        ))}
        {data.beforeLink && <hr />}
      </div>
    );
  } else {
    return <></>;
  }
};

// Add ad-hoc links at top or bottom of accordion
const PrepAccordion = ({
  data,
  key,
}: Readonly<{
  data: AccordionDirType;
  key: string;
}>) => {
  return (
    <>
      {data.beforeLink && <AddLink data={data} />}
      <Accordion key={key} data={data} />
      {data.afterLink && <AddLink data={data} />}
    </>
  );
};

const ResoucePages = () => {
  // URL parameters to set data for page
  const [page, ,] = useSearchParams();
  const pageDataName: string = page.get("page") ?? "studentInfoData";

  // Call Home Service api for the Maint links
  const { isSuccess: pageDataIsSuccess, data: allPageData } = useQuery(
    "resourceData",
    () => axios.get(resourceEndpoint),
    {
      staleTime: Infinity,
    },
  );

  if (pageDataIsSuccess) {
    // Get data. Default to Student Info
    const pageDataMap = allPageData
      ? new Map(Object.entries(allPageData.data))
      : new Map();
    const pageData = pageDataMap.get(pageDataName);

    return (
      <section id="resource-page" className="accordion">
        {/* Defult APU page banner */}
        <Banner />
        {/* Accordion */}
        <div id="resource-inner">
          {pageData.map((item: AccordionDirType) => (
            <PrepAccordion key={uuid()} data={item} />
          ))}
        </div>
      </section>
    );
  } else {
    return <></>;
  }
};

export default ResoucePages;
