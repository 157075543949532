import { useState } from "react";
import { useQuery } from "react-query";
import { ClipLoader } from "react-spinners";
import axios, { AxiosError } from "axios";
import $ from "jquery";

import Button from "#components/Button";
import { apiURL } from "#components/ApiUrlConverter";
import "./ServiceCreditsTable.css";
import DraftIcon from "#images/icons/icon_circle_draft.png";
import SWIcon from "#images/icons/icon_circle_supervisor_wait.png";
import SAIcon from "#images/icons/icon_circle_supervisor_affirm.png";
import SubmittedIcon from "#images/icons/icon_circle_submitted.png";
import CanceledIcon from "#images/icons/icon_circle_canceled.png";
import DeniedIcon from "#images/icons/icon_circle_denied.png";
import ApprovedIcon from "#images/icons/icon_circle_approved.png";

interface SCR {
  EMPLID: string;
  APU_CSA_SEQ: number;
  APU_CSA_NAME: string;
  APU_CSA_CREDITS: string;
  DESCR: string;
  XLATSHORTNAME: string;
  SHORTNAME: string;
  APU_MIN_CLR_GRAD: string;
  INSTITUTION: string;
  ACAD_CAREER: string;
  STRM: string;
  FIELDNAME: string;
  FIELDVALUE: string;
  APU_MIN_CREDIT_NUM: number;
}

const ServiceCreditsTable = () => {
  const endpoint = apiURL("/api/v1/users/scrs");

  const { isSuccess: scrsIsSuccess } = useQuery(
    "userProfile",
    () => axios.get(endpoint),
    {
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          window.location.reload();
        }
      },
      onSuccess: (scrsData) => {
        // Sort the scrs based on the STRM
        setSortedScrs(
          scrsData.data.sort((a: SCR, b: SCR) => {
            return Number(b.STRM) - Number(a.STRM);
          }),
        );
        // Set common attributes from the first name of the data
        setEmplid(scrsData.data[0].EMPLID);
        setEnvName(`${scrsData.data[0].SHORTNAME}STU`);
        setGradAppStatus(scrsData.data[0].APU_MIN_CLR_GRAD);
        setMinimumCredits(scrsData.data[0].APU_MIN_CREDIT_NUM);

        // Calculate the approved service credits
        let approvedCredits = 0;
        scrsData.data.forEach((scr: SCR) => {
          if (scr.FIELDVALUE == "APRV") {
            approvedCredits += isNaN(Number(scr.APU_CSA_CREDITS))
              ? 0
              : Number(scr.APU_CSA_CREDITS);
          }
        });
        setApprovedCredits(approvedCredits);
      },
      staleTime: Infinity,
    },
  );
  const [sortedScrs, setSortedScrs] = useState([]);
  const [emplid, setEmplid] = useState<string | null>();
  const [envName, setEnvName] = useState<string | null>();
  const [gradAppStatus, setGradAppStatus] = useState<string | null>();
  const [minimumCredits, setMinimumCredits] = useState<number | null>();
  const [approvedCredits, setApprovedCredits] = useState<number | null>();

  const formatTermString = (termString: string) => {
    // Find the width and height of the table
    const width = $("#service-credits").width();
    // If the width of the table is less than 735, create shortened strings
    if (width && width < 735) {
      const shortTermString = termString
        .replace(" B", "")
        .replace(" 20", " ")
        .replace("Summer", "Sum.")
        .replace("Spring", "Spr.");
      return shortTermString;
    } else return termString;
  };

  const getStatusIcon = (statusText: string) => {
    switch (statusText) {
      case "APRV":
        return ApprovedIcon;
      case "CNCL":
        return CanceledIcon;
      case "DENY":
      case "RVSD":
        return DeniedIcon;
      case "DRFT":
      case "OFFC":
        return DraftIcon;
      case "REVS":
        return SWIcon;
      case "RVSA":
        return SAIcon;
      case "SBMT":
        return SubmittedIcon;
      default:
        return DraftIcon;
    }
  };

  const getGradStatusString = (gradStatus: string) => {
    switch (gradStatus) {
      case "CLR":
        return (
          <>
            <p className="style9">Your Graduation Status:</p>
            <p className="style1">
              Our records indicate that you have started the Graduation
              application. To assist you, we have listed your current service
              credit standing below.
            </p>
            <p className="style1">
              Current Service Credit Status:{" "}
              <span className="style2">CLEARED</span>
            </p>
            <p className="style1">
              Congratulations! You have completed the service credit
              requirements for graduation.
            </p>
          </>
        );
      case "NCLR":
        return (
          <>
            <p className="style9">Your Graduation Status:</p>
            <p className="style1">
              Our records indicate that you have started the Graduation
              application. To assist you, we have listed your current service
              credit standing below.
            </p>
            <p className="style1">
              Current Service Credit Status:{" "}
              <span className="style4">PENDING</span>
            </p>
            <p className="style1">
              You are still pending service credits to graduate. Please look at
              the above table and make sure that it accurately reflects your
              current number of credits. If you have recently submitted a
              service credit report, it is possible those reports are still
              being processed. Please contact the Center for Student Action to
              process your remaining service credits and receive a clearance for
              graduation.
            </p>
          </>
        );
      case "WALK":
        return (
          <>
            <p className="style9">Your Graduation Status:</p>
            <p className="style1">
              Our records indicate that you have started the Graduation
              application. To assist you, we have listed your current service
              credit standing below.
            </p>
            <p className="style1">
              Current Service Credit Status:{" "}
              <span className="style4">Cleared to Walk</span>
            </p>
            <p className="style1">
              You are eligible to participate in commencement and 'walk' in the
              graduation ceremony. You are still pending service credits and
              must submit them all in order for your degree to be posted. Please
              look at the above table and make sure that it accurately reflects
              your current number of credits. If you have recently submitted a
              service credit report, it is possible those reports are still
              being processed. Please contact the{" "}
              <a href="http://www.apu.edu/studentaction/" target="_blank">
                Center for Student Action
              </a>{" "}
              to process your remaining service credits and receive clearance
              for your degree to post.
            </p>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <section id="service-credits">
      {scrsIsSuccess ? (
        minimumCredits == 0 ? (
          <div id="service-credits-no-req">
            <h2>You do not have a service credits requirement to fufill.</h2>
            <p>
              If you believe this is a mistake, please{" "}
              <a href="https://www.centerforstudentaction.org/contact">
                contact the Center for Student Action.
              </a>
            </p>
          </div>
        ) : (
          <div id="service-credits-table">
            <div id="service-credits-key">
              <ul>
                <li>
                  <img
                    className="status-icon"
                    src={DraftIcon}
                    title="Draft"
                    alt="Draft"
                  />
                  <div>Draft</div>
                </li>
                <li>
                  <img
                    className="status-icon"
                    src={SWIcon}
                    title="Under Review"
                    alt="Under Review"
                  />
                  <div>Supervisor Wait</div>
                </li>
                <li>
                  <img
                    className="status-icon"
                    src={SAIcon}
                    title="Supervisor Affirm"
                    alt="Supervisor Affirm"
                  />
                  <div>Supervisor Affirm</div>
                </li>
                <li>
                  <img
                    className="status-icon"
                    src={SubmittedIcon}
                    title="Submitted"
                    alt="Submitted"
                  />
                  <div>Submitted</div>
                </li>
                <li>
                  <img
                    className="status-icon"
                    src={CanceledIcon}
                    title="Cancelled"
                    alt="Cancelled"
                  />
                  <div>Cancelled</div>
                </li>
                <li>
                  <img
                    className="status-icon"
                    src={DeniedIcon}
                    title="Denied"
                    alt="Denied"
                  />
                  <div>Denied</div>
                </li>
                <li>
                  <img
                    className="status-icon"
                    src={ApprovedIcon}
                    title="Approved"
                    alt="Approved"
                  />
                  <div>Approved</div>
                </li>
              </ul>
            </div>
            <table>
              <tr>
                <th colSpan={2}>Organization</th>
                <th>Credits</th>
                <th>Term</th>
                <th>Status</th>
              </tr>
              {sortedScrs ? (
                sortedScrs.map((scr: SCR) => (
                  <tr>
                    <td className="style6" colSpan={2}>
                      <a
                        href={`https://${
                          envName == "PRODSTU" ? "home" : envName
                        }.apu.edu/psc/${envName}/EMPLOYEE/HRMS/c/APU_CUSTOM.APU_CSA_SCR_SLFSVC.GBL?EMPLID=${emplid}&APU_CSA_SEQ=${
                          scr.APU_CSA_SEQ
                        }&cmd=login&auth=cas`}
                      >
                        {scr.APU_CSA_NAME}
                      </a>
                    </td>
                    <td>
                      <p>{scr.APU_CSA_CREDITS}</p>
                    </td>
                    <td>
                      <p>{formatTermString(scr.DESCR)}</p>
                    </td>
                    <td>
                      <img
                        className="status-icon"
                        src={getStatusIcon(scr.FIELDVALUE)}
                        title={scr.XLATSHORTNAME}
                        alt={scr.XLATSHORTNAME}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr></tr>
              )}
            </table>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p className="style5">
                Total Approved Credits:{" "}
                <span
                  className={
                    approvedCredits &&
                    minimumCredits &&
                    approvedCredits > minimumCredits
                      ? "style5b"
                      : "style5c"
                  }
                >
                  {approvedCredits}
                </span>
              </p>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p className="style5">
                Estimated Required Credits:{" "}
                <span className="style5a">{minimumCredits}</span>
              </p>
            </div>
            {gradAppStatus ? getGradStatusString(gradAppStatus) : <></>}
            <Button
              id="new_scr"
              href={`https://${
                envName == "PRODSTU" ? "home" : envName
              }.apu.edu/psc/${envName}/EMPLOYEE/HRMS/c/APU_CUSTOM.APU_CSA_SCR_SLFSVC.GBL?EMPLID=${emplid}&APU_CSA_SEQ=0&cmd=login&auth=cas`}
              name={["New Service Credit Report"]}
            />
            <p id="questions">
              *If you have any questions,{" "}
              <a
                target="_blank"
                style={{ color: "#900", textDecoration: "underline" }}
                href="https://www.centerforstudentaction.org/contact"
              >
                please contact the Center for Student Action
              </a>
            </p>
          </div>
        )
      ) : (
        <ClipLoader color={"#700"} size={50} />
      )}
    </section>
  );
};
export default ServiceCreditsTable;
